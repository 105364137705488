<template>
  <div id="destinations">
    <Hero/>
    <div class="destinations-section">
      <h1>Destinations</h1>
      <div v-for="destination in destinations" :key="destination.name">
        <PropertyCardLg :name="destination.name" :url="destination.url" :src="destination.src" :rivers="destination.rivers"/>
      </div>
    </div>
    <BookNow/>
  </div>
</template>

<script>
import Hero from '@/components/Hero.vue'
import PropertyCardLg from '@/components/PropertyCardLg.vue'
import BookNow from '@/components/BookNow.vue'
import destinations from '../data/destinations.json'
export default {
  components: { 
    Hero, 
    PropertyCardLg,
    BookNow
  },
  data() {
    return {
      destinations: destinations
    };
  },
}
</script>


<style lang="scss">
#destinations {
  .destinations-section {
    width: 65%;
    margin: auto;
    h1 {
      text-align: left;
      margin-top: 50px;
    }
  }
  @media only screen and (max-width: 500px) {
    .destinations-section {
      width: 90%;
      margin: auto;
    }
  }
}
</style>